<template>
  <div class="min-vh-screen" v-loading="isLoading"></div>
</template>

<script>
export default {
  name: 'ConferenceRedirect',
  data () {
    return {
      isLoading: true
    }
  },
  async created () {
    await this.getConferenceCircleDetail()
  },
  methods: {
    async getConferenceCircleDetail () {
      try {
        if (!this.$route.params?.code) {
          this.$router
            .replace({
              path: '/'
            })
            .catch(() => {})
          return
        }
        const res = await this.$rf
          .getRequest('ConferenceRequest')
          .getConferenceCircleDetailByCode(this.$route.params?.code)

        if (!res.data) {
          this.$router
            .replace({
              path: '/'
            })
            .catch(() => {})
          return
        }

        this.$router
          .replace({
            name: 'WorkspaceConferenceDetail',
            params: {
              id: res?.data?.circle_id
            }
          })
          .catch(() => {})
        return
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>
</style>
